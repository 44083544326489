
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { StaffListModel } from "@/domain/staff/staffList/model/StaffListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "staff-list",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToStaffDetail(staffId) {
      this.$router.push(`/hotelManagement/staffList/${staffId}`);
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const staffController = store.state.ControllersModule.staffController;
    const staffList = ref<StaffListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const getStaffList = async () => {
      isLoading.value = true;

      const staffListModel: StaffListModel = {
        staff: {},
        page: 1,
        pageSize: 50,
      };

      staffController
        .staffList(staffListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((staff) => {
              staffList.value.push(staff);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.hotel.title"));
      getStaffList();
    });

    return {
      loading,
      staffList,
      isLoading,
    };
  },
});
